"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DemoFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const system_1 = require("@mui/system");
const DemoFooter = () => {
    const theme = (0, material_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)("div", { className: "w-full p-6", style: { backgroundColor: theme.palette.primary.light }, children: (0, jsx_runtime_1.jsx)("div", { className: "max-w-7xl mx-auto", children: (0, jsx_runtime_1.jsxs)(material_1.Grid, { container: true, spacing: 2, alignItems: "center", children: [(0, jsx_runtime_1.jsxs)(material_1.Grid, { item: true, xs: 9, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { sx: { color: theme.palette.primary.main }, variant: "h4", children: (0, jsx_runtime_1.jsx)("strong", { children: "Take the next step" }) }), (0, jsx_runtime_1.jsxs)(material_1.Typography, { color: 'black', variant: "subtitle2", children: ["It\u2019s simple to get started with Feesable, and you can be setup and live within a week! Sign up today, or if you have more questions, feel free to reach out at info@feesable.com.au, or", ' ', (0, jsx_runtime_1.jsx)(material_1.Link, { href: "https://feesable.com.au/custom-demo/", children: "book a call" }), " to have a chat."] })] }), (0, jsx_runtime_1.jsx)(material_1.Grid, { item: true, xs: 3, children: (0, jsx_runtime_1.jsx)(system_1.Box, { display: "flex", justifyContent: { xs: 'center', md: 'flex-end' }, children: (0, jsx_runtime_1.jsx)(material_1.Button, { href: "https://feesable.com.au/sign-up/", variant: "contained", sx: {
                                    backgroundColor: theme.palette.primary.dark,
                                    padding: 2,
                                    borderRadius: 7,
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                }, type: "button", size: "medium", disableElevation: true, children: "Sign up" }) }) })] }) }) }));
};
exports.DemoFooter = DemoFooter;
