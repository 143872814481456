"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const wouter_1 = require("wouter");
const services_1 = require("../services");
const Footer_1 = require("./Footer");
const Header_1 = require("./Header");
const Widget_1 = require("./Widget");
const App = ({ school, frame, showHeading, testMode }) => {
    const [config, setConfig] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        (0, services_1.getConfigData)(school).then((data) => {
            setConfig(data);
            // window.feesableConfigData = data
        });
    }, [school]);
    const schoolData = (0, react_1.useMemo)(() => config === null || config === void 0 ? void 0 : config.data, [config]);
    // Used only for the old iFrame embed mode
    const iframeWidgetComponent = (0, react_1.useMemo)(() => {
        const widgetProps = {
            school,
            headingtext: 'Fee Calculator',
            hideheading: false,
            includepadding: false,
            hidesidebar: true,
            testmode: null,
        };
        if (frame === 'shadow' || frame === 'padding') {
            widgetProps.includepadding = true;
        }
        if (typeof showHeading === 'string' && showHeading.length > 0) {
            switch (showHeading) {
                case 'true': {
                    widgetProps.hideheading = false;
                    break;
                }
                case 'false': {
                    widgetProps.hideheading = true;
                    break;
                }
                default: {
                    widgetProps.hideheading = false;
                    widgetProps.headingtext = showHeading;
                }
            }
        }
        return (0, jsx_runtime_1.jsx)(Widget_1.Widget, Object.assign({}, widgetProps));
    }, [schoolData, frame, showHeading]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(wouter_1.Route, { path: "/embed", children: iframeWidgetComponent }), (0, jsx_runtime_1.jsx)(wouter_1.Route, { path: "/", children: (0, jsx_runtime_1.jsxs)("div", { id: "feesable-app", className: "flex flex-col h-screen", children: [(0, jsx_runtime_1.jsx)(Header_1.Header, { logoUrl: schoolData === null || schoolData === void 0 ? void 0 : schoolData.details.logo, name: (schoolData === null || schoolData === void 0 ? void 0 : schoolData.name) || '', websiteUrl: schoolData === null || schoolData === void 0 ? void 0 : schoolData.details.websiteUrl }), (0, jsx_runtime_1.jsx)("div", { className: "w-full md:p-12 bg-white flex-grow", children: (0, jsx_runtime_1.jsx)("div", { className: "max-w-7xl mx-auto flex items-center md:justify-between flex-col md:flex-row p-3", children: (0, jsx_runtime_1.jsx)(Widget_1.Widget, { school: school, headingtext: 'Fee Calculator', hideheading: false, includepadding: false, hidesidebar: false, testmode: testMode }) }) }), (0, jsx_runtime_1.jsx)(Footer_1.Footer, { name: (schoolData === null || schoolData === void 0 ? void 0 : schoolData.name) || '', websiteUrl: (schoolData === null || schoolData === void 0 ? void 0 : schoolData.details.websiteUrl) || '', privacyUrl: (schoolData === null || schoolData === void 0 ? void 0 : schoolData.details.privacyUrl) || '' })] }) })] }));
};
exports.App = App;
