"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@mui/material/styles");
const globalTheme = (0, styles_1.createTheme)({
    typography: {
        fontSize: 14,
    },
    palette: {
        primary: {
            main: '#00BCD4',
            light: '#EBFEFF',
            dark: '#07769B',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#FF5F57',
            light: '#FF572240',
        },
        secondary: {
            light: '#ffe27a',
            main: '#feca0e',
            dark: '#222',
            contrastText: '#222',
        },
        grey: {
            50: '#ddd',
            100: '#777',
            500: '#444',
            A100: '#FFFFFF',
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
        },
        custom: {
            surfaceLight: '#FFFFFF',
            surfaceMedium: '#F5F5F5',
            surfaceDark: '#D9D9D9',
            primary: '#00BCD4',
            primaryMedium: '#B2EBF2',
            primaryLight: '#E0F7FA',
            text: '#212121',
            textMedium: '#424242',
            textLight: '#757575',
        },
    },
    // header: {
    //   height: 60,
    // },
});
const theme = (0, styles_1.createTheme)(globalTheme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '&#feesable-app': {
                    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                },
                strong: {
                    color: 'inherit',
                },
                li: {
                    margin: 0,
                    '&:before': {
                        display: 'none',
                        content: 'none',
                    },
                },
                button: {
                    minWidth: 0,
                    maxWidth: 'none',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    '&.Mui-error': {
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: globalTheme.palette.error.main,
                        backgroundColor: globalTheme.palette.error.light,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: 16,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    input: {
                        boxShadow: 'none',
                        '&:focus': {
                            border: 'none',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    paddingTop: 12,
                    paddingBottom: 12,
                },
                outlined: {
                    '&:hover': {
                        color: 'black',
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({ ownerState }) => (Object.assign({ borderColor: theme.palette.custom.primary, color: theme.palette.custom.primary, width: 60, '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: (0, styles_1.darken)(theme.palette.primary.main, 0.1),
                        },
                    } }, ((ownerState.meta || {}).error && {
                    borderColor: globalTheme.palette.error.main,
                    color: theme.palette.error.main,
                }))),
            },
        },
    },
    overrides: {
        MuiCard: {
            root: {
                width: '100%',
            },
        },
        MuiCardContent: {
            root: {
                padding: 32,
                [globalTheme.breakpoints.down('md')]: {
                    padding: 0,
                },
            },
        },
        MuiPaper: {
            elevation1: {
                [globalTheme.breakpoints.down('md')]: {
                    boxShadow: 'none',
                },
            },
        },
        MuiTypography: {
            overline: {
                lineHeight: 1.5,
            },
        },
        MuiToggleButton: {
            root: {
                width: 60,
                borderColor: globalTheme.palette.primary.main,
                color: globalTheme.palette.primary.main,
                '&.Mui-selected': {
                    backgroundColor: globalTheme.palette.primary.main,
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: globalTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontFamily: 'Roboto',
                fontSize: 12,
                color: globalTheme.palette.custom.textLight,
                paddingLeft: '8px',
            },
        },
        MuiInput: {
            underline: {
                '&:hover:not($disabled):before': {
                    borderBottomWidth: '2px',
                },
            },
        },
        MuiInputAdornment: {
            root: {
                color: globalTheme.palette.primary.main,
            },
            positionStart: {
                paddingTop: 24,
                paddingBottom: 24,
                paddingLeft: 8,
                paddingRight: 8,
            },
        },
        /*MuiDivider: {
          root: {
            backgroundColor: globalTheme.palette.custom.primary,
            marginTop: 26,
            marginBottom: 26
          }
        },*/
        MuiAccordion: {
            root: {
                backgroundColor: globalTheme.palette.primary.main,
                color: '#FFFFFF',
                boxShadow: 'none',
                width: '100%',
                '&.Mui-expanded': {
                    marginTop: 0,
                    marginBottom: 0,
                    '&.Mui-expanded:before': {
                        opacity: 1,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
                backgroundColor: globalTheme.palette.primary.main,
                flexDirection: 'column',
            },
        },
        MuiAccordionSummary: {
            expandIcon: {
                color: '#FFFFFF',
                '&.Mui-expanded': {
                    transform: 'rotate(-90deg)',
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: globalTheme.palette.custom.textMedium,
                marginBottom: 8,
                display: 'block',
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 0,
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: globalTheme.palette.primary.main,
                color: '#FFFFFF',
            },
        },
        MuiDialogContent: {
            root: {
                paddingBottom: 16,
            },
        },
    },
});
exports.default = (0, styles_1.responsiveFontSizes)(theme, { factor: 2.2 });
