"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DemoHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const DemoHeader = ({ logoUrl, name, websiteUrl }) => {
    const defaultWebsiteUrl = 'https://feesable.com.au';
    const theme = (0, material_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)("header", { className: "header", children: (0, jsx_runtime_1.jsxs)("div", { className: "max-w-7xl mx-auto p-3 flex items-center justify-between", style: {
                flexWrap: 'nowrap',
                flexDirection: 'row',
                gap: '1rem',
            }, children: [(0, jsx_runtime_1.jsx)("a", { href: websiteUrl !== null && websiteUrl !== void 0 ? websiteUrl : defaultWebsiteUrl, target: "_blank", rel: "noreferrer", children: logoUrl && ((0, jsx_runtime_1.jsx)("img", { className: "logo", src: logoUrl, alt: name, style: {
                            width: 'auto',
                            height: '60px',
                            objectFit: 'contain',
                        } })) }), (0, jsx_runtime_1.jsx)(material_1.Button, { href: "https://feesable.com.au/sign-up/", variant: "contained", sx: {
                        backgroundColor: theme.palette.primary.dark,
                        padding: 2,
                        borderRadius: 7,
                        textTransform: 'none',
                        fontWeight: 'bold',
                    }, type: "button", size: "medium", disableElevation: true, children: "Start using Feesable" })] }) }));
};
exports.DemoHeader = DemoHeader;
